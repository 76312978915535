import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"
import ProjectsSection from "../components/Projects"
import Layout from "../components/layout"
import { Contact } from "../components/Contact"


const IndexPage = () => {
  
const data = useStaticQuery(
  graphql`{
  bio: allProject(filter: {education: {ne: null}}) {
    nodes {
      about
      tools
      education
    }
  }
  projects: allProject(filter: {name: {ne: null}}) {
    nodes {
      description
      img
      name
      url
      tools
      color
    }
  }
  images: allFile(
            filter: {sourceInstanceName: {eq: "images"}, relativeDirectory: {eq: "thumbnails"}}
        ) {
            edges {
            node {
                relativePath
                childImageSharp {
                    gatsbyImageData
                }
                id
                name
            }
            }
        }
}
`) 
  console.log(data);
  const sortedProjects = data.projects.nodes.sort((a, b) => (a.order > b.order) ? 1 : -1);
  return (
    <Layout>
      <ProjectsSection sortedProjects={sortedProjects} data={data} />
      <section className="about">
        <h2>About Me</h2>
        <p>{data.bio.nodes[0].about}</p>
        <h3>Education: {data.bio.nodes[0].education}</h3>
        <h3>My Favorite Tools</h3>
                      <ul className={`tool-list`}>{
                
                data.bio.nodes[0].tools?.map((tool, index) => {
                    return( 
                        <li className={`tool-item purple`} key={index}> 
                            {tool}
                        </li>)
                })
            }</ul>
      </section>
      <Contact />
    </Layout>
  )
}




export default IndexPage
