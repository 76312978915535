import React from "react";
import { useState } from "react";

export function Contact() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const checkInputEmpty = () => {
    const nameEmpty = name.length == 0;
    const emailEmpty = email.length == 0;
    const subjectEmpty = subject.length == 0;
    const messageEmpty = message.length == 0;
    if (!nameEmpty && !emailEmpty && !subjectEmpty && !messageEmpty) {
      setButtonDisabled(false);
      return;
    }
    setButtonDisabled(true);
  }
  
  const handleChange = e => {
    switch (e.target.id) {
      case "name":
        setName(e.target.value);
        break;
      case "email":
        setEmail(e.target.value);
        break;
      case "subject":
        setSubject(e.target.value);
        break;
      case "message":
        setMessage(e.target.value);
        break;
      default:
        return;
    };
    checkInputEmpty();
  };



  const sendEmail = async () => {
    const res = fetch('https://cptbda8ddf.execute-api.us-west-1.amazonaws.com/sendEmail',
      {
        mode: 'no-cors',
        method: 'POST',
        headers: {
          Accept: 'application/json',
          "Content-Type": 'application/json'
        },
        body: JSON.stringify({
          name: name,
          email: email,
          subject: subject,
          message: message,
        })
      });
  };
  const handleSubmit = () => {
    if (name && email && subject && message) {
      sendEmail();
    }
  };

  return (
    <section className="contact">
      <h2>Contact Me.</h2>
      <form>
        <div className="form-left">
          <label className="contact-label">Your Name</label>
          <input type="text" id="name" placeholder="e.g. Robert Jordan" value={name} onChange={(e) => handleChange(e)}/>
          <label className="contact-label">Email</label>
          <input type="text" id="email" placeholder="e.g. hello@example.com" value={email} onChange={(e) => handleChange(e)}/>
          <label className="contact-label">Subject</label>
          <input type="text" id="subject" placeholder="e.g. Reaching Out" value={subject}onChange={(e) => handleChange(e)}/>

        </div>
        <div className="form-right">
          <label className="contact-label">Message</label>
          <textarea type="text" value={message} onChange={(e) => handleChange(e)} id="message" placeholder="Hey there! Really like the portfolio!" rows="9">
          </textarea>
          <button type="submit" disabled={buttonDisabled} onClick={handleSubmit}>Send</button>
        </div>
      </form>
    </section>
  )
} 