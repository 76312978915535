import React from "react";
import Project from "./Project";


export default function ProjectsSection({sortedProjects, data}) {
  return (< section className="projects" >
    <h2>Personal Projects</h2>
    <ul className="project-list">
      {sortedProjects.map((item, index) => {
        let thumbnail = data.images.edges.find((element) =>
          element.node.name === item.img);
        return (
          <Project project={item} key={index} thumbnail={thumbnail} />
        )
      })}
    </ul>
  </section>)
}