import React from 'react'
import {GatsbyImage, getImage} from 'gatsby-plugin-image';
import { Link } from 'gatsby';


function Project({ project, thumbnail }) {
    
    const { node } = thumbnail == undefined ? null : thumbnail;
    return (
        <li className={`project-item project-${project.color}`} >
          <div>
                      <div>
            <GatsbyImage image={getImage(node)} alt={node.name} key={node.id}/> 
        </div>
            <h3>{project.name}</h3>
            <p>{project.description}</p>
                <Link to={project.url}><button className={project.color}>Check it Out!</button></Link>
            <h4>Tools</h4>
            <ul className={`tool-list ${project.color}`}>{
                
                project.tools?.map((tool, index) => {
                    return( 
                        <li className={`tool-item ${project.color}`} key={index}> 
                            {tool}
                        </li>)
                })
            }</ul>
        </div>

    </li>
  )
}



export default Project